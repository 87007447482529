<script setup>

const analytics = useAnalytics();

const props = defineProps({
  to: [String, Object],
  target: String,
  rel: String,
  can: {
    type: Boolean,
    default: true
  },
  dataLayer: {
    type: Object,
    default: null
  }
})

const executeAnalytics = () => {
 const data = toRaw(props.dataLayer);
  if(!data) return;
  analytics.analyticsLayer(data);
}

</script>

<template>
  <NuxtLink v-if="to" :to="to" :target="target" :rel="rel" :class="[$attrs.class, {'pointer-events-none': !can}]" @click="executeAnalytics">
    <slot />
  </NuxtLink>
  <div v-else :class="[$attrs.class]">
    <slot />
  </div>
</template>
